import React from 'react';
import { Canvas, useThree, useFrame, useLoader } from '@react-three/fiber';
import { Float, useGLTF, PresentationControls, Environment, ContactShadows, Loader } from '@react-three/drei';
import * as THREE from 'three';

// Helper function to get correct asset paths
const getAssetPath = (path) => {
  // Remove leading slash if present to avoid double slashes
  const cleanPath = path.startsWith('/') ? path.slice(1) : path;
  return `/wp-content/3d/physiocert/${cleanPath}`;
};
function Model(props) {
  const { scene } = useGLTF(getAssetPath('models/PhysioCert-Logo-007.glb'));
  const floatingMeshes = React.useRef({});
  const initialPositions = React.useRef({});
  const initialRotations = React.useRef({});

    // Update texture paths using the helper function
    const sockelTexture = useLoader(
      THREE.TextureLoader, 
      getAssetPath('textures/matcaps/CDC3B8_5A3F3C_67545A_8D7769-256px.png')
    );
    const aussenTexture = useLoader(
      THREE.TextureLoader, 
      getAssetPath('textures/matcaps/aussen-4.png')
    );
    const innenTexture = useLoader(
      THREE.TextureLoader, 
      getAssetPath('textures/matcaps/80A6B4_D5E9EF_B2D0D9_C1DCE4-256px.png')
    );
    const schriftTexture = useLoader(
      THREE.TextureLoader, 
      getAssetPath('textures/matcaps/aussen-4.png')
    );
    const logoTexture = useLoader(
      THREE.TextureLoader, 
      getAssetPath('textures/matcaps/422509_C89536_824512_0A0604-256px.png')
    );

  React.useEffect(() => {

    [sockelTexture, aussenTexture, innenTexture, schriftTexture].forEach(texture => {
      texture.encoding = THREE.sRGBEncoding; // oder THREE.LinearEncoding je nach Bedarf
      texture.needsUpdate = true;
    });

    scene.traverse((child) => {
      if (child.isMesh) {
        const floatingElements = ['ring-aussen', 'ring-innen', 'schriftzug', 'schweizerkreuz', 'flagge'];
        if (floatingElements.includes(child.name.toLowerCase())) {
          floatingMeshes.current[child.name] = child;
          // Speichern der Initialwerte als Euler-Winkel
          initialPositions.current[child.name] = child.position.clone();
          initialRotations.current[child.name] = new THREE.Euler(
            child.rotation.x,
            child.rotation.y,
            child.rotation.z,
            'XYZ'
          );
        }

        // Material-Zuweisung
        switch(child.name.toLowerCase()) {
          case 'sockel':
            child.material = new THREE.MeshMatcapMaterial({ matcap: sockelTexture});
            break;
          case 'ring-aussen':
            child.material = new THREE.MeshStandardMaterial({ color: new THREE.Color().setStyle('#005f8e'),roughness:0.6, metalness:0.3});
            break;
          case 'ring-innen':
            child.material = new THREE.MeshStandardMaterial({ color: new THREE.Color().setStyle('#c9ebff'),roughness:0.6, metalness:0.3});
            break;
          case 'schriftzug':
            child.material = new THREE.MeshStandardMaterial({ color: new THREE.Color().setStyle('#005f8e'), roughness:0.6, metalness:0.3});
            break;
          case 'logo':
            child.material = new THREE.MeshMatcapMaterial({ matcap: logoTexture});
            break;
          case 'text':
            child.material = new THREE.MeshMatcapMaterial({ matcap: logoTexture});
            break;
            case 'schweizerkreuz':
              child.material = new THREE.MeshStandardMaterial({ color: new THREE.Color().setStyle('#ffffff'), roughness:0.6, metalness:0.3});
            break;
        }
      }
    });
  }, [scene, sockelTexture, aussenTexture, innenTexture, schriftTexture]);

  /* // Animation
  useFrame((state) => {
    const time = state.clock.getElapsedTime();
  
    Object.entries(floatingMeshes.current).forEach(([name, mesh]) => {
      const initialPos = initialPositions.current[name];
      const initialRot = initialRotations.current[name];
  
      // Vertikale Wipp-Animation
      const posFrequency = 0.6;
      const posAmplitude = 0.03;
      mesh.position.set(
        initialPos.x,
        initialPos.y + Math.sin(time * posFrequency) * posAmplitude,
        initialPos.z
      );
  
      // Rotations-Animation
      const rotFrequency = 0.4;
      const rotAmplitude = 0.05;
      
      // Setzen der Rotation zurück auf Initialwerte und dann Addition der Animation
      mesh.rotation.set(
        initialRot.x,
        initialRot.y,
        initialRot.z + Math.sin(time * rotFrequency) * rotAmplitude // Nur die Animations-Komponente wird addiert
      );
    });
  });
 */
   return (
    <group {...props}>
      <primitive object={scene} />
{/*       <Html
        position={[0.2, 1.3, -1.5]} // Rechts neben dem Modell
        rotation={[0, Math.PI / 2.7, 0]} // Rotation um Y-Achse um 90 Grad
        transform
        distanceFactor={3.7} // Kontrolliert die Größe
        style={{
          transition: 'all 0.2s',
          opacity: 1,
          transform: 'scale(1)',
        }}
      >
        <div className="annotation">
          Mehr Infos <span style={{ marginLeft: '5px' }}>👉</span>
        </div>
      </Html> */}
    </group>
  ); 
}
function Rig() {
  const { camera, mouse } = useThree();
  const vec = new THREE.Vector3();
  return null;
}

export default function LogoViewer() {
  return (
    <div class="physiocert">
      <Canvas 
        shadows
        camera={{ position: [0, 1.5, 10], fov: 25 }}
        style={{ 
          width: '100%', 
          touchAction: 'none'
        }}
      >
        <color attach="background" args={['#ffffff']} />
        <ambientLight intensity={2.0} />
        <spotLight
          position={[10, 10, 10]}
          intensity={1}
          angle={0.15}
          penumbra={1}
          shadow-mapSize={1024}
          castShadow
        />
        
        <PresentationControls
          global
          config={{ mass: 2, tension: 500 }}
          snap={{ mass: 4, tension: 1500 }}
          rotation={[0, 0.6, 0]}
          polar={[-Math.PI / 3, Math.PI / 3]}
          azimuth={[-Math.PI / 1.4, Math.PI / 2]}
          zoom={1}
        >

        <Float rotationIntensity={0.4} floatIntensity={1.2} speed={1.3}>
          <Model 
            rotation={[0, -Math.PI*0.65, 0]} 
            position={[0, -0.4, 0]} 
            scale={1.0}
          />
        </Float>
        </PresentationControls>

        <ContactShadows
          position={[0, -1.4, 0]}
          opacity={0.3}
          scale={10}
          blur={3}
          far={4}
        />
        <Environment preset="studio" environmentIntensity={0.16}/>
        <Rig />
      </Canvas>
      <Loader
        containerStyles={{ 
          background: 'white',
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center' 
        }}
        innerStyles={{}} 
        barStyles={{ 
          background: '#2c3966' 
        }}
        dataStyles={{ 
          color: '#2c3966', 
          fontFamily: 'sans-serif', 
          fontSize: '0.8em' 
        }}
        dataInterpolation={p => `Loading ${p.toFixed(2)}%`}
      />
    </div>
  );
}
